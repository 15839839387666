/**
 * @param {Object} context contains values for replacing placeholders
 */
export default function replaceBlock({ context = null }) {
  if (!context) {
    throw new Error('replaceBlock: Invalid required argument context')
  }

  /**
   * Return value of context property defined by path, or an empty string
   * @param {String} placeholder is a string of dot separated words indicating a path, e.g. '{{company.address.city}}'
   * @returns {String} with the replaced value
   */
  function getContextValueByPlaceholder(placeholder) {
    const path = placeholder.replace(/{{|}}/g, '').trim()
    const keys = path.split('.')
    const value = keys.reduce((acc, key) => {
      return acc[key] || ''
    }, context)
    return value
  }

  /**
   * Replace all placeholders by context values in text
   * @param {String} text
   */
  function getReplacedText(text) {
    return text.replace(/{{.*?}}/g, getContextValueByPlaceholder)
  }

  /**
   * @param {Object} block element with properties whose values have placeholders to be replaced by values from context
   * @returns the same block, where placeholders have been replaced by context values
   * E.g. block: {title: 'Work for {{company.name}}'}
   * context: {company: { name: 'JRPL' }}
   * Will return:
   * {title: 'Work for JRPL'}
   */
  function getReplacedBlock(block) {
    // recursively apply on array elements
    if (Array.isArray(block)) {
      return block.map(getReplacedBlock)
    }

    // recursively apply on object properties
    if (block && typeof block === 'object') {
      const replacedEntries = Object.entries(block).map(([key, value]) => {
        return [key, getReplacedBlock(value)]
      })
      return Object.fromEntries(replacedEntries)
    }

    // replace placeholders by context
    if (typeof block === 'string') {
      return getReplacedText(block)
    }

    // ignore any non string primitive types
    return block
  }

  return { getReplacedBlock, getReplacedText }
}
